import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Productes } from 'src/app/components/models/product_categories';

@Injectable({
  providedIn: 'root'
})
export class ApisProductsService {
  BaseUrl ='http://api.roots-organic.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  
  public getAllProducts():  Observable<Productes>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<Productes>(this.BaseUrl+ 'admin/admin_general/product/get' ,{headers});
  }
  public deleteProducts(body):  Observable<Productes>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<Productes>(this.BaseUrl+ 'admin/admin_general/product/delete' ,body,{headers});
  }

  public editeProducts(body ):  Observable<Productes>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<Productes>(this.BaseUrl+ 'admin/admin_general/product/edit' ,body,{headers});
  }
  public addProducts(body):  Observable<Productes>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<Productes>(this.BaseUrl+ 'admin/admin_general/product/add' ,body,{headers});
  }
}
