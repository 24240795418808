    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <h3>Categories
                                <span class="buttonModale">
                                    <app-add-category (childAdded)="onChildAdded($event)"></app-add-category>
                                </span>
                            </h3>
                        </div>
                        <div class="table-rep-plugin">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table id="tech-companies-1" class="table  table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>select</th>
                                        <th>Image</th>
                                        <th>Name</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody  *ngIf='!loading'>
                                    <tr *ngFor='let item of categories'>
                                        <th>{{item.id}}</th>
                                        <th><input type="checkbox" #checkboxes type="checkbox" name="chek" data-md-icheck ngModel ></th>
                                        <th><img [src]="item?.image"></th>
                                        <th> {{item?.name}} </th>
                                        <td>
                                            <ul class="StatusIcon">
                                                <li class="edit"><app-edite-category [categories]=item (childEdited)='onChildAdded($event)'></app-edite-category></li>
                                                <li class="delete" (click)='clickSweet(item.id)'><i class="fa fa-trash"></i></li>
                                            </ul>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div class="responsiveLoading" *ngIf='loading'>
                                    <span><i class="fa fa-circle-o-notch fa-spin"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- end row -->
        </div> <!-- container -->
    </div> <!-- content -->


